<template>
 <v-container class="ma-0 pa-0 text-center">
   <hero
     v-bind="series"
     :src="getHeroSrc(series)"
     :gradientTop="getGradientTop()"
     :gradientBottom="getGradientBottom()"
   />
   <v-tabs
     v-model="tab"
     background-color="transparent"
     grow
   >
     <v-tab key="episodes" > episodes </v-tab>
     <v-tab key="about" > about </v-tab>
   </v-tabs>

   <v-tabs-items v-model="tab" class="transparent">
   <v-tab-item key="episodes">
   <v-row no-gutters>
     <v-col
       cols="12"
       md="3"
       v-if="showSeasonSelector"
     >
       <v-select
         v-model="selectedSeason"
         :items="['Season 2', 'Season 1', 'Any']"
         class="px-3"
       ></v-select>
     </v-col>
     <v-col>
     <v-text-field
          v-if="episodes.length > 0"
          v-model="search"
          label="Search"
          append-outer-icon="mdi-magnify"
          @click:clear="clearSearch"
          clearable
          class="px-3"
        ></v-text-field>
     </v-col>
   </v-row>
   <v-row
      class="fill-height mt-0"
      align="center"
      justify="center"
    >
      <template v-for="(episode, i) in filteredEpisodes">
        <v-col
          :key="i"
          cols="12"
          md="3"
        >
          <episode-card
            v-bind="episode"
            :src="getImageSrc(series, episode)"
          />
        </v-col>
      </template>
      <p v-if="episodes.length == 0 && series.title" class="series-desc">{{series.title}} is coming soon!</p>
    </v-row>
   </v-tab-item>
   <v-tab-item key="about">
     <p v-html="series.description" class="series-desc"/>
   </v-tab-item>
   </v-tabs-items>
  </v-container>
  <!--
   <base-section id="gallery">
    <v-tabs
      v-model="tab"
      centered
      class="mb-12"
    >
      <v-tab
        v-for="(tab, i) in tabs"
        :key="i"
        :href="`#${tab.toLowerCase()}`"
        class="text-capitalize"
      >
        {{ tab }}
      </v-tab>
    </v-tabs>

    <v-container>
      <v-fade-transition
        group
        hide-on-leave
        tag="div"
        class="row"
      >
        <v-col
          v-for="episode in episodes"
          :key="episode.id"
          class="gallery-card"
          cols="12"
          sm="6"
          md="3"
        >
          <episode-card
            v-bind="episode"
            :src="'/episodes/' + episode.episode_identifier + '.jpg'"
            height="265"
            width="100%"
          />

        </v-col>
      </v-fade-transition>
    </v-container>
   </base-section>
  <v-container>
    <h1>{{ series.title }}</h1>
    <v-container fluid>
      <v-row dense>
        <v-col
          v-for="episode in episodes"
          :key="episode.id"
          :cols="episode.flex"
        >
          <v-card>
            <v-img
              :src="episode.src"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="200px"
            >
              <v-card-title v-text="episode.title"></v-card-title>
              <a :href="'/podcast/' + episode.slug">click</a>
            </v-img>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn icon>
                <v-icon>mdi-heart</v-icon>
              </v-btn>

              <v-btn icon>
                <v-icon>mdi-bookmark</v-icon>
              </v-btn>

              <v-btn icon>
                <v-icon>mdi-share-variant</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
  -->
</template>
<script>
import axios from 'axios'
// import moment from 'moment'
// @ is an alias to /src
export default {
  name: 'Episode',
  components: {
    EpisodeCard: () => import('@/components/EpisodeCard'),
  },
  data: () => ({
    selectedSeason: 'Season 2',
    search: '',
    episodes: [],
    series: {},
    tab: null,
    tabs: [
      'episodes',
      'about',
    ],
  }),
  computed: {
    showSeasonSelector () {
     if(this.series.current_season_num > 1) {
       // eslint-disable-next-line vue/no-side-effects-in-computed-properties
       this.selectedSeason = 'Season ' + this.series.current_season_num
       return true
     } else {
       // eslint-disable-next-line vue/no-side-effects-in-computed-properties
       this.selectedSeason = 'Any'
       return false
     }
    },
    filteredEpisodes () {
      if(this.search !== null && this.search !== '') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.selectedSeason = 'Any'
        return this.episodes.filter(episode => {
          let searchString = this.search.toLowerCase()
          return episode.title.toLowerCase().indexOf(searchString) !== -1
        })
      } else {
        if (this.selectedSeason.startsWith('Season ')) {
          return this.episodes.filter(episode => {
            return episode.series_season === this.selectedSeason.replace('Season ', '')
          })
        } else {
          return this.episodes
        }
      }
    }

  },
  methods: {
    clearSearch: function() {
     this.search = '';
    },
    getImageSrc: function(series, episode) {
      if (series.slug === 'junk-science' ) {
      //if (series.slug == 'false-confessions' || series.slug == 'junk-science' ) {
      //if (series.slug == 'false-confessions' || series.slug == 'junk-science' || series.slug == 'bone-valley') {
        return '/episodes/' + series.slug + '.jpg'
      } else {
        return '/episodes/' + series.slug + '/' + episode.episode_identifier + '.jpg'
      }
    },
    getGradientTop: function() {
      //if (series.slug == 'bone-valley' || series.slug == 'with-maggie-freleng' || series.slug == 'righteous-convictions' ) {
        return 0.4
      //} else {
      //  return 0.7
      //}
    },
    getGradientBottom: function() {
      //if (series.slug == 'bone-valley' || series.slug == 'with-maggie-freleng' || series.slug == 'righteous-convictions' ) {
        return 0.3
      //} else {
      //  return 0.6
      //}
    },
    getHeroSrc: function(series) {
      return '/series/' + series.slug + '.jpg'
    }
  },
  mounted () {
    axios
      .get('/api/v1/episodes/' + this.$route.params.slug)
      .then((response) => {
        //console.log(response)
        this.episodes = response.data.episodes
        this.series = response.data.series
      })
      .catch((error) => {
          console.log(error)
      })
  }
}
</script>
<style lang="sass">
  .gallery-card
    transform-origin: center center 0
    transition: .3s cubic-bezier(.25,.8,.50,1)

  .series-desc
    text-align: left
    margin: 2rem
    font-size: 1.1rem
    color: #dcdcdc

  .series-desc img.headshot
    float: left
    height: 240px
    padding: 0.4em 2.4em 1em 0


</style>

